import { Style } from "./Style";
import { FetchWithAuth } from "digimaker-ui/util";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router";
import FileUpload from "digimaker-ui/FileUpload";

const Edit = (props) => {
  const nav = useNavigate();
  const [title, setTitle] = useState("");
  const [year, setYear] = useState("");
  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);
  const [file3, setFile3] = useState(null);
  const [file4, setFile4] = useState(null);

  useEffect(() => {
    FetchWithAuth(`content/get/${props.id}`)
      .then((data) => {
        if (data.error === false) {
          setTitle(data.data.title);
          setYear(data.data.year);
          setFile1(data.data.body[0]?.path);
          setFile2(data.data.body[1]?.path);
          setFile3(data.data.body[2]?.path);
          setFile4(data.data.body[3]?.path);
        }
      })
      .catch((d) => {
        window.alert(d.message);
        nav(`/`);
      });
  }, [props.id]);
  const save = (data) => {
    if (year === "") {
      alert("Please select a year");
      return;
    }
    console.log(data);
    if (!file1 || !file2 || !file3 || !file4) {
      window.alert("Please upload all files before submitting.");
      return;
    }
    const jsonData = {
      title: data.page.title,
      year: data.page.year,
      body: [
        { name: "audit", path: file1 },
        { name: "test2", path: file2 },
      ],
    };
    FetchWithAuth(`content/update/${props.id}`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(jsonData),
    })
      .then(() => {
        nav("/website/main/1151");
      })
      .catch((d) => {
        window.alert(d.message);
        nav(`/`);
      });
  };

  return (
    <form
      style={{ ...Style.container, flexDirection: "column" }}
      onSubmit={(e) => {
        e.preventDefault();
        save({ page: { title: title, year: year } });
      }}
    >
      <h2>Edit Annual Report</h2>
      <label style={Style.label} htmlFor="title">
        Title
      </label>
      <input
        style={Style.input}
        type="text"
        id="title"
        name="title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />

      <select
        style={Style.select}
        id="year"
        name="year"
        value={year}
        onChange={(e) => setYear(e.target.value)}
      >
        <option value="">Please select year</option>
        <option value="2020">2020</option>
        <option value="2021">2021</option>
        <option value="2022">2022</option>
        <option value="2023">2023</option>
        <option value="2024">2024</option>
      </select>
      <label style={Style.label} htmlFor="file1">
        Styrets beretning
      </label>
      <FileUpload
        value={""}
        name={"file1"}
        service="content"
        format={"pdf"}
        onSuccess={(info) => {
          console.log(info);
          setFile1(info.nameUploaded);
        }}
      />

      <label style={Style.label} htmlFor="file1">
        Årsregnskap
      </label>
      <FileUpload
        value={""}
        name={"file2"}
        service="content"
        format={"pdf"}
        onSuccess={(info) => {
          setFile2(info.nameUploaded);
        }}
      />

      <label style={Style.label} htmlFor="file1">
        Revisjonsberetning
      </label>
      <FileUpload
        value={""}
        name={"file3"}
        service="content"
        format={"pdf"}
        onSuccess={(info) => {
          setFile3(info.nameUploaded);
        }}
      />

      <label style={Style.label} htmlFor="file1">
        Årsmøteprotokoll
      </label>
      <FileUpload
        value={""}
        name={"file4"}
        service="content"
        format={"pdf"}
        onSuccess={(info) => {
          setFile4(info.nameUploaded);
        }}
      />

      <button style={Style.button} type="submit" name="submit">
        Submit
      </button>
    </form>
  );
};

export default Edit;
