import { useEffect, useState } from "react";
import { useEditorStore } from "dmeditor";
import { DME } from "dmeditor";
import { TextField } from "@mui/material";

const Number = (props: DME.SettingComponentProps) => {
  const { property, value, parameters } = props;
  const { updateSelectedBlockProps } = useEditorStore();

  const handleChange = (v) => {
    updateSelectedBlockProps(property || "", v);
  };

  const getMinMax = () => {
    let minMax = {};
    if (parameters) {
      if (parameters["min"]) {
        minMax["min"] = parameters["min"];
      }
      if (parameters["max"]) {
        minMax["max"] = parameters["max"];
      }
    }
    return minMax;
  };

  const validate = (e) => {
    if ([8, 46].includes(e.keyCode)) {
      return;
    }
    if (isNaN(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <>
      <TextField
        type="number"
        size="small"
        InputProps={{ inputProps: getMinMax() }}
        value={value as number}
        onKeyDown={validate}
        onChange={(e) => handleChange(e.target.value)}
      />
    </>
  );
};

export default Number;
