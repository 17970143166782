import {
  registerWidget,
  registerSettingComponent,
  registerWidgetStyle,
  registerWidgetStyleOption,
} from "dmeditor";
import definition from "./definition";
import { ArticleDetail, onServerSideLoad } from "./ArticleDetail";
import { ArticleImage } from "./settings/ArticleImage";

const register = () => {
  registerWidget(definition, {
    render: ArticleDetail,
    onServerSideLoad: onServerSideLoad,
  });
  registerSettingComponent("article-image", ArticleImage);
  registerWidgetStyleOption("text", [
    {
      identifier: "article-summary",
      name: "Summary",
      cssStyle: `
      font-weight:bold;
    `,
    },
  ]);
};

export default register;
