import { registerWidget, registerWidgetStyleOption } from "dmeditor";

import BridgeCarousel from "./BridgeCarousel";
import definition from "./definition";

export default () => {
  registerWidget(definition, {
    render: BridgeCarousel,
  });
  registerWidgetStyleOption("bridge-carousel", [
    {
      identifier: "default",
      name: "Default",
      cssClasses: {
        root: "bridge-carousel",
      },
      cssStyle: `     
        width: var(--dme-container-width);
        margin-left: calc( ( var(--dme-main-width) - var(--dme-container-width) ) / 2 );
        .dme-wrapper-carousel{
          height: 85vh;
        }

        .preview-dmeditor & .dme-wrapper-carousel, .preview-dmeditor & .dme-wrapper-carousel .carousel-item img{
            height: 600px;
        }

        .dme-blocktype-carousel{
          button{
          }
        }  
        
        .carousel-item img{
          height: 85vh;
          width: inherit;
          max-width: none;
          object-fit: cover;          
        }

        .dme-viewmode-mobile &{

          .dme-blockvariant-button > .dme-blocktype-list{
            display:block;
          }
          .dme-wrapper-carousel{
            height: 70vh;
          }

          .carousel-item img{
            height: 70vh;
          }


          & .dme-blockvariant-button .dme-block-container:not(:last-child){
            margin-right: 0px;
          }

          .dme-blockvariant-button .dme-block-container a{
            margin-bottom: 10px;
            min-width: 200px;
          }
        }
      
      & > div:not(:first-child){
        position: absolute;
        text-align: center;
        width: var(--dme-container-width);    
        z-index: 100;         
      }

      & > .bridge-carousel-text{
        top: 30%;        
        p{
          color: white;
          font-size: 2.5rem; 
          font-family: var(--font-Cambria);
          font-weight: bold;
        } 
      }


      & > .bridge-carousel-buttons{
        bottom: 20% 
      }

      & .dme-blockvariant-button .dme-block-container:not(:last-child){
          margin-right: 15px;
      }

      & .dme-blockvariant-button .dme-block-container a{
        padding: 8px 50px;
        border-radius: 0px;
        font-size: 20px;
      }
     
      .dme-carousel-image {
        width: 100%;
      }
      `,
    },
  ]);
  //   registerSettingComponent('bridge-carousel', SettingCarousel);
};
