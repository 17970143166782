//import 'bootstrap/dist/css/bootstrap.css';
export const Style = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '80%',
        margin: '0 auto',
        backgroundColor: '#f8f9fa',
        padding: '20px',
        borderRadius: '5px',
        boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
    },
    label: {
        fontWeight: 'bold',
        marginTop: '20px',
        fontSize: '1.2em',
    },
    input: {
        width: '100%',
        padding: '10px',
        marginTop: '10px',
        marginBottom: '20px',
        borderRadius: '5px',
        border: '1px solid #ced4da',
    },
    select: {
        width: '100%',
        padding: '10px',
        marginTop: '10px',
        marginBottom: '20px',
        borderRadius: '5px',
        border: '1px solid #ced4da',
    },
    button: {
        width: '100px',
        padding: '10px',
        marginTop: '20px',
        marginBottom: '20px',
        backgroundColor: '#007bff',
        color: 'white',
        borderRadius: '5px',
        border: 'none',
        cursor: 'pointer',
    },
    
    fileUpload: {
        width: '100%',
        padding: '10px',
        marginTop: '10px',
        marginBottom: '20px',
        borderRadius: '5px',
        border: '1px solid #ced4da',
      },
};
