//Init in pure server side & pure admin.
//Mixed client can not use this(need to output to client).
export let siteEnv = {
  clubID: 0,
  kretsID: 0, //7
  basePath: "", //for link purpose, not routing.
  folderNews: 0, //526
  folderAnnualMeeting: 0, //1819
  folderEvents: 0, //1812
  folderImage: 0,
  folderFile: 0,
};

export const nbfEnv = {
  folderTemplate: 1096,
  folderLink: 2803,
  folderFooter: 2884,
};

export type SiteEnvType = {
  clubID: number;
  basePath: string;
  kretsID: number;
  folderNews: number;
  folderAnnualMeeting: number;
  folderEvents: number;
  folderImage: number;
  folderFile: number;
};

export const updateSiteEnv = (sEnv: SiteEnvType) => {
  siteEnv = sEnv;
};

//update site config from content
export const setSiteEnv = (folderList, clubID, kretsID, basePath) => {
  const libaryParents = {
    folderNews: 0,
    folderAnnualMeeting: 0,
    folderEvents: 0,
    folderImage: 0,
    folderFile: 0,
  };
  for (const folder of folderList) {
    const folderType = folder.folder_type;
    if (folderType) {
      const folderLocationID = folder.location.id;
      switch (folderType) {
        case "annual_report":
          libaryParents.folderAnnualMeeting = folderLocationID;
          break;
        case "event":
          libaryParents.folderEvents = folderLocationID;
          break;
        case "article":
          libaryParents.folderNews = folderLocationID;
          break;
        case "image":
          libaryParents.folderImage = folderLocationID;
          break;
        case "file":
          libaryParents.folderFile = folderLocationID;
          break;
        default:
          break;
      }
    }
  }

  updateSiteEnv({
    clubID: clubID,
    kretsID: kretsID,
    basePath: basePath,
    ...libaryParents,
  });
};
