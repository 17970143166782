import { BlockListRender, BlockRender } from "dmeditor";
import { DME } from "dmeditor";

const BridgeCarousel = (props: DME.WidgetRenderProps) => {
  const {
    blockNode: { children },
    mode,
    path,
    styleClasses,
    rootClasses,
  } = props;

  return children ? (
    <>
      <div
        className={
          styleClasses["bridge-carousel"] || "bridge-carousel-carousel"
        }
      >
        {children[0] && (
          <BlockRender mode={mode} data={children[0]} path={[...path, 0]} />
        )}
      </div>
      <div className="bridge-carousel-text">
        {children[1] && (
          <BlockRender mode={mode} data={children[1]} path={[...path, 1]} />
        )}
      </div>
      <div className="bridge-carousel-buttons">
        {children[2] && (
          <BlockRender mode={mode} data={children[2]} path={[...path, 2]} />
        )}
      </div>
    </>
  ) : (
    <></>
  );
};

export default BridgeCarousel;
