import { ImageSetting, useEditorStore } from "dmeditor";
import { DME } from "dmeditor";
import styled from "@emotion/styled";
import { Checkbox } from "@mui/material";
import { useState } from "react";

import { EntityArticleInfo } from "../entity";

const Container = styled.div`
  display: flex;
  align-items: flex-start;

  & label {
    white-space: nowrap;
    margin-right: 40px;
    font-size: 14px;
  }
`;

export const ArticleImage = () => {
  const { getSelectedBlock, updateSelectedBlock, page, updatePage } =
    useEditorStore();
  const { data } = getSelectedBlock<EntityArticleInfo>() || {};
  const { image } = data || {};

  const [checked, setChecked] = useState(page.cover_image === image?.src);

  const handleChange = (value: DME.ImageInfo) => {
    updateSelectedBlock<EntityArticleInfo>((blockData) => {
      blockData.image = value;
    });
  };

  const handleCheckedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const coverImage = e.target.checked ? image?.src || "" : "";

    setChecked(e.target.checked);
    updatePage(coverImage, "cover_image");
  };

  return (
    <>
      <Container>
        <label>Image</label>
        <div className="content">
          <ImageSetting
            value={{ src: image?.src || "", id: image?.id }}
            onChange={handleChange}
          />
        </div>
      </Container>
      <Container>
        <label>Cover Image</label>
        <div className="content">
          <Checkbox
            disabled={!image?.src}
            checked={checked}
            onChange={handleCheckedChange}
          />
        </div>
      </Container>
    </>
  );
};
