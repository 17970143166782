import { DME } from "dmeditor";
import moment from "moment";
import { useEffect, useState } from "react";
import { Tabs, Tab, Table } from "react-bootstrap";
import { EntityResult } from "./entity";
import { Link } from "../Shared";
import { siteEnv } from "../siteEnv";
import { wrapBasePath } from "../util";

const Result = (props: DME.WidgetRenderProps<EntityResult>) => {
  const {
    blockNode: { data },
    rootClasses,
  } = props;

  const [value, setValue] = useState(data.value);

  moment.locale("nb");

  const dateWidth = 150;

  const fetchData = () => {
    const clubID = siteEnv.clubID;
    fetch(`https://www.bridge.no/result/list/${clubID}?json=true`)
      .then((res) => res.json())
      .then((data) => {
        setValue(data);
      });
  };

  useEffect(() => {
    if (!props.blockNode.serverData) {
      fetchData();
    }
  }, []);

  if (!value || !value.years) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-white container">
      <Tabs defaultActiveKey="0" className="mb-3">
        {value.years.map((year, index) => (
          <Tab eventKey={index} title={year}>
            <div>
              <h3>Sesongresultater</h3>
              {value.summary_list[year] && (
                <div>
                  {Object.keys(value.summary_list[year]).map((name) => (
                    <div>
                      <Link
                        to={wrapBasePath("/result/" + name + "?summary=true")}
                      >
                        {value.summary_list[year][name].ContestName}
                      </Link>
                    </div>
                  ))}
                </div>
              )}
              <br />

              {(() => {
                const yearData = value.contest_list[year];
                const filenameArr = Object.keys(yearData);

                let group = {} as any;
                let month = "";
                let monthMap = {
                  "01": "jan",
                  "02": "feb",
                  "03": "mar",
                  "04": "apr",
                  "05": "mai",
                  "06": "jun",
                  "07": "jul",
                  "08": "august",
                  "09": "sep",
                  "10": "okt",
                  "11": "nov",
                  "12": "des",
                };
                for (let filename of filenameArr) {
                  let dateMoment = moment(
                    yearData[filename].ContestDate as string
                  );
                  let currentMonth = dateMoment.format("MM");
                  if (currentMonth !== month) {
                    group[currentMonth] = {};
                    month = currentMonth;
                  }
                  group[month][dateMoment.format("DD.MM.YYYY")] = {
                    ...yearData[filename],
                    file: filename,
                  };
                }
                let months = Object.keys(group);
                months.sort((a, b) => {
                  return parseInt(b) - parseInt(a);
                });
                return months.map((month) => (
                  <>
                    <h3>
                      {monthMap[month]} {year}
                    </h3>
                    <Table striped>
                      <tbody>
                        {Object.keys(group[month]).map((date) => (
                          <tr>
                            <td style={{ width: dateWidth }}>{date}</td>
                            <td>
                              <Link
                                to={wrapBasePath(
                                  "/result/" + group[month][date]["file"]
                                )}
                              >
                                {group[month][date].ContestName}
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </>
                ));
              })()}
            </div>
          </Tab>
        ))}
      </Tabs>
    </div>
  );
};

const onServerSideLoad = async (blockData, context) => {
  const clubID = siteEnv.clubID;
  const res = await fetch(
    `https://www.bridge.no/result/list/${clubID}?json=true`
  );
  const jsonData = await res.json();
  blockData.data.value = jsonData;
  blockData.serverData = true;
};

export { Result, onServerSideLoad };
