import { nanoid } from "nanoid";

import { AnnualMeeting, onServerSideLoad } from "./AnnualMeeting";
import { registerWidget } from "dmeditor";

const registerAnnualMeeting = () => {
  console.log("AnnualMeeting:", AnnualMeeting);
  registerWidget(
    {
      type: "bridge-annual-meeting",
      name: "Annual meeting",
      category: "bridge",
      icon: "bridge",
      settings: [],

      events: {
        createBlock: () => ({
          id: nanoid(),
          type: "bridge-annual-meeting",
          data: {
            settings: {
              width: 900,
              height: 1200,
              header: "Årsmøte",
            },
          },
        }),
        updateData: () => void 0,
      },
    },
    { render: AnnualMeeting, onServerSideLoad: onServerSideLoad }
  );
};

export default registerAnnualMeeting;
