import { DMEditor, DMEditorRefType } from "dmeditor";
import { FetchWithAuth } from "digimaker-ui/util";
import { useEffect, useRef } from "react";
import { dmeditorFields } from "./fieldConfig";
import { useNavigate, useSearchParams } from "react-router-dom";
import { pageSettings } from "./pageSetting";
import {
  getArticleSummaryFromList,
  jsonToHtml,
} from "../../../components/util";

export const Create = (props: { parent: number; contentType: string }) => {
  const editorRef = useRef<DMEditorRefType>(null);
  const nav = useNavigate();
  const [searchParams] = useSearchParams();

  const params = useSearchParams();

  let sending = false;

  const save = (data) => {
    if (sending) {
      return;
    }

    const jsonData = data.data;

    const postData = {
      title: data.page.title,
      coverimage: data.page.cover_image || "",
    };
    postData[dmeditorFields[props.contentType]] = jsonData;

    if (props.contentType === "article") {
      const summaryHtml = getArticleSummaryFromList(jsonData);
      postData["summary"] = summaryHtml;
    }

    sending = true;
    FetchWithAuth(`content/create/${props.contentType}/${props.parent}`, {
      method: "POST",
      body: JSON.stringify(postData),
    })
      .then((data) => {
        sending = false;
        if (data.error === false) {
          window.alert("Created. Click to return");
          if (searchParams.has("from")) {
            nav(("/website" + searchParams.get("from")) as string);
          } else {
            nav(`/website/main/${props.parent}`);
          }
        }
      })
      .catch((d) => {
        sending = false;
        window.alert(d.message);
        nav(`/`);
      });
  };

  const cancel = () => {
    if (searchParams.has("from")) {
      nav(("/website" + searchParams.get("from")) as string);
    } else {
      nav(`/website/main/${props.parent}`);
    }
  };

  const fetchData = async () => {
    const template = params[0].get("template");
    let templateData = [];
    let title = "Ny side";
    if (template) {
      const templateContent = await FetchWithAuth(
        `content/get/template/${template}`
      );
      if (templateContent) {
        templateData = templateContent.data.body;
        title = templateContent.data.title;
      }
    }

    const current = editorRef.current;
    if (current) {
      current.setPageSettings(pageSettings as any);
      current.setData(templateData);
      current.setPageData({ title: title, cover_image: "" });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return <DMEditor ref={editorRef} onSave={save} onCancel={cancel} />;
};
