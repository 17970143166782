import { FetchWithAuth } from "digimaker-ui/util";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import useSiteStore from "../store/useSiteStore";
import { useNavigate } from "react-router";
import { initSite } from "./initSite";

export const ChangeSite = () => {
  const {
    site: { id: siteID },
    sites,
    setSite,
  } = useSiteStore();
  const navigate = useNavigate();

  const selectSite = async (v) => {
    if (v) {
      const rootID = parseInt(v);
      const selected = sites.find((item) => item.location.id === rootID);
      await initSite(sites, rootID);
      setSite({
        id: rootID,
        name: selected["_club"]["club_name"],
        host: selected["_club"]["host"],
        clubID: selected["_club"]["club_id"],
        basePath: selected["_club"]["basePath"],
      });
      navigate("/");
    }
  };

  return sites.length > 0 ? (
    <div>
      <Form.Select
        size="sm"
        aria-label="Select site"
        onChange={(e) => selectSite(e.target.value)}
      >
        <option value={""} disabled>
          Select site
        </option>
        {sites.map((itemSite: any) => (
          <option
            selected={siteID === itemSite.location.id}
            value={itemSite.location.id}
          >
            {itemSite["_club"].org_name} ({itemSite.location.id})
          </option>
        ))}
      </Form.Select>
    </div>
  ) : (
    <></>
  );
};
