import { FetchWithAuth } from "digimaker-ui/util";
import { setSiteEnv } from "shared/src/widgets/siteEnv";

export const initSite = async (sites, rootID) => {
  const selected = sites.find((item) => item.location.id === rootID);
  const root = selected.location.id;
  const data = await FetchWithAuth(
    "content/list/folder?parent=" + root + "&level=1&limit=-1"
  );

  if (data.error === false) {
    const folders = data.data.list.filter(
      (item) => item.folder_type && item.folder_type.value
    );
    const updateFolders: Array<any> = [];
    for (const item of folders) {
      item.folder_type = item.folder_type.value;
      updateFolders.push(item);
    }

    setSiteEnv(
      updateFolders,
      selected["_club"]["club_id"],
      selected["_club"]["parent_org_id"],
      selected["_club"]["basePath"]
    );
  }
};
