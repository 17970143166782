import styled from "@emotion/styled";

export const StyleDiv = styled.div`
  .klubber {
    height: 145px;
    background: #d5dfdc;
    display: flex;
    font-size: 2rem;
  }
  .klubber > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .klubberTitle {
    color: #222222;
  }

  .dmeditor-view:not(.dme-viewmode-mobile) & {
    .klubberTitle {
      width: 360px;
    }

    .klubbergroup {
      display: flex;
      width: calc(100% - 360px);
    }

    .klubber-item {
      width: 50%;
      margin-left: 10px;
      height: 66px;
    }
  }

  .dmeditor-view.dme-viewmode-mobile & {
    .klubberTitle {
      font-size: 1.25rem;
      padding: 10px 20px;
      background: #f0f0f0;
      text-align: left;
      margin: 0;
      width: 100%;
    }

    .klubberTitle:after {
      content: "";
      display: block;
      width: 2px;
      height: 1.25rem;
      margin: -24px 0 0 -10px;
      background: var(--waring-color);
    }

    .klubber {
      height: auto;
      background: #fff;
    }

    .klubber > div {
      flex-direction: column;
    }

    .klubbergroup {
      padding: 10px;
      width: 100%;
      flex-direction: column;
    }
    .klubber-item {
      height: 55px;
      width: 100%;
    }
  }

  .klubber-item {
    margin: 0 0 10px 0;
  }

  .klubber-item select {
    border-radius: 0;
    text-align: center;
    font-size: 22px;
    font-family: "Arial";
    color: #666;
  }
`;
